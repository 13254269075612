// import axios from "axios";
import customersStore from "./customers.store";
import eventsStore from "./events.store";
import membershipsStore from "./memberships.store";
import newsStore from "./news.store";
import settingsStore from "./settings.store";
import ticketsStore from "./tickets.store";
import venuesStore from "./venues.store";

// default state
const getDefaultState = () => {
  return {};
};

// initial state
const state = getDefaultState();

// getters
const getters = {};

// actions
const actions = {};

// mutations
const mutations = {};

// modules
const modules = {
  customersStore,
  eventsStore,
  newsStore,
  ticketsStore,
  venuesStore,
  settingsStore,
  membershipsStore,
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
  modules,
};
