import store from "@/stores/";

export default [
  {
    path: "communal-repairs",
    component: () => import("@/modules/sbpm/views/Template"),
    children: [
      {
        path: "",
        name: "module-sbpm-communalrepairs",
        component: () =>
          import("@/modules/sbpm/views/communal-repairs/Dashboard"),
        beforeEnter: (to, from, next) => {
          store.commit("showHideLoader", true);
          Promise.all([
            store.dispatch("sbpm/repairsStore/loadRepairs", {
              appId: to.params.id,
            }),
            store.dispatch("sbpm/propertiesStore/loadProperties", {
              appId: to.params.id,
            }),
            store.dispatch("users/loadModule", {
              appId: to.params.id,
              module: "users",
            }),
          ]).then(() => {
            store.commit("showHideLoader", false);
            next();
          });
        },
      },
      {
        path: "archived",
        name: "module-sbpm-communalrepairs-archived",
        component: () =>
          import("@/modules/sbpm/views/communal-repairs/Archived"),
        beforeEnter: (to, from, next) => {
          store.commit("showHideLoader", true);
          Promise.all([
            store.dispatch("sbpm/repairsStore/loadRepairs", {
              appId: to.params.id,
            }),
          ]).then(() => {
            store.commit("showHideLoader", false);
            next();
          });
        },
      },
      {
        path: ":repairId",
        name: "module-sbpm-communalrepairs-individual",
        component: () =>
          import("@/modules/sbpm/views/communal-repairs/Individual"),
        beforeEnter: (to, from, next) => {
          store.commit("showHideLoader", true);
          Promise.all([
            store.dispatch("sbpm/repairsStore/loadRepair", {
              appId: to.params.id,
              repairId: to.params.repairId,
            }),
            store.dispatch("users/loadModule", {
              appId: to.params.id,
              module: "users",
            }),
            store.dispatch("sbpm/propertiesStore/loadProperties", {
              appId: to.params.id,
            }),
          ]).then(() => {
            store.commit("showHideLoader", false);
            next();
          });
        },
      },
    ],
  },
];
