import axios from "axios";
import { getApiUrl } from "@/utils";

const state = {
  repairs: [],
  archived: [],
  repair: {},
};

// getters
const getters = {
  all: (state) => state.repairs,
  archived: (state) => state.archived,
  get: (state) => state.repair,
};

// actions
const actions = {
  loadRepairs({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/sbpm/repairs`,
        method: "GET",
      })
        .then((resp) => {
          commit("loadRepairsSuccess", { ...resp.data });
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  loadRepair({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/sbpm/repairs/${data.repairId}`,
        method: "GET",
      })
        .then((resp) => {
          commit("loadRepairSuccess", { ...resp.data });
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  saveNote({ commit }, data) {
    const url = `${getApiUrl(data.appId)}/module/sbpm/repairs/${
      data.repairId
    }/note`;

    let fields = data.fields;

    let formData = new FormData();
    for (const field in fields) {
      if (fields[field] === null) {
        formData.append(field, "");
      } else {
        formData.append(field, fields[field]);
      }
    }

    return new Promise((resolve, reject) => {
      axios({
        url,
        data: formData,
        method: "POST",
      })
        .then((resp) => {
          commit("saveNoteSuccess", {
            ...resp.data,
          });
          resolve(resp);
        })
        .catch((err) => reject(err));
    });
  },

  saveRepairAddition({ commit }, data) {
    const url = data.isEditing
      ? `${getApiUrl(data.appId)}/module/sbpm/repairs/${
          data.repairId
        }/addition/${data.additionId}`
      : `${getApiUrl(data.appId)}/module/sbpm/repairs/${
          data.repairId
        }/addition`;

    let fields = data.fields;

    let formData = new FormData();
    for (const field in fields) {
      if (typeof fields[field] === "boolean") {
        formData.append(field, fields[field] ? 1 : 0);
      } else if (fields[field] === null) {
        formData.append(field, "");
      } else {
        formData.append(field, fields[field]);
      }
    }

    return new Promise((resolve, reject) => {
      axios({
        url,
        data: formData,
        method: "POST",
      })
        .then((resp) => {
          commit("saveRepairAdditionSuccess", {
            ...resp.data,
            isEditing: data.isEditing,
          });
          resolve(resp);
        })
        .catch((err) => reject(err));
    });
  },

  saveRepair({ commit }, data) {
    const url = data.isEditing
      ? `${getApiUrl(data.appId)}/module/sbpm/repairs/${data.repairId}`
      : `${getApiUrl(data.appId)}/module/sbpm/repairs`;

    let fields = data.fields;

    let formData = new FormData();
    for (const field in fields) {
      if (typeof fields[field] === "boolean") {
        formData.append(field, fields[field] ? 1 : 0);
      } else if (fields[field] === null) {
        formData.append(field, "");
      } else {
        formData.append(field, fields[field]);
      }
    }

    return new Promise((resolve, reject) => {
      axios({
        url,
        data: formData,
        method: "POST",
      })
        .then((resp) => {
          commit("saveRepairSuccess", {
            ...resp.data,
            isEditing: data.isEditing,
          });
          resolve(resp);
        })
        .catch((err) => reject(err));
    });
  },

  restore({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/sbpm/repairs/${
          data.repairId
        }/restore`,
        method: "POST",
      })
        .then((resp) => {
          commit("restoreRepairSuccess", { ...resp.data });
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  deleteRepair({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/sbpm/repairs/${data.repairId}`,
        method: "DELETE",
      })
        .then(() => {
          commit("deleteRepairSuccess", {
            repairId: data.repairId,
          });
          resolve();
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
};

// mutations
const mutations = {
  loadRepairsSuccess(state, data) {
    state.repairs = data.repairs;
    state.archived = data.archived;
  },

  loadRepairSuccess(state, data) {
    state.repair = data.repair;
  },

  saveRepairSuccess(state, data) {
    if (data.isEditing) {
      const index = state.repairs.findIndex((p) => p.id === data.repair.id);
      state.repairs.splice(index, 1, data.repair);

      state.repair = data.repair;
    } else {
      state.repairs.push(data.repair);
    }
  },

  saveRepairAdditionSuccess(state, data) {
    if (data.isEditing) {
      const index = state.repair.additional.findIndex(
        (p) => p.id === data.addition.id
      );
      state.repair.additional.splice(index, 1, data.addition);
    } else {
      state.repair.additional.push(data.addition);
    }

    state.repair = data.addition.repair;
  },

  restoreRepairSuccess(state, data) {
    const index = state.archived.findIndex((c) => c.id === data.repairId);
    state.archived.splice(index, 1);
    state.repairs.push(data.checkout);
  },

  saveNoteSuccess(state, data) {
    state.repair.notes.push(data.note);
  },

  deleteRepairSuccess(state, data) {
    const index = state.repairs.findIndex((c) => c.id === data.repairId);
    state.repairs.splice(index, 1);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
