import store from "@/stores/";

export default [
  {
    path: "memberships",
    component: () => import("@/modules/craigtoun/views/Template"),
    beforeEnter: (to, from, next) => {
      store.commit("showHideLoader", true);
      store.dispatch("craigtoun/membershipsStore/loadMemberships", {
        appId: to.params.id,
      }),
        store
          .dispatch("craigtoun/customersStore/loadCustomers", {
            appId: to.params.id,
          })
          .then(() => {
            store.commit("showHideLoader", false);
            next();
          });
    },
    children: [
      {
        path: "",
        name: "module-craigtoun-memberships",
        component: () =>
          import("@/modules/craigtoun/views/memberships/Dashboard"),
      },
      {
        path: ":membershipId",
        name: "module-craigtoun-memberships-individual",
        component: () =>
          import("@/modules/craigtoun/views/memberships/Individual"),
        beforeEnter: (to, from, next) => {
          store.commit("showHideLoader", true);
          store.dispatch("craigtoun/membershipsStore/loadMembership", {
            appId: to.params.id,
            membershipId: to.params.membershipId,
          }),
            store
              .dispatch("craigtoun/customersStore/loadCustomers", {
                appId: to.params.id,
              })

              .then(() => {
                store.commit("showHideLoader", false);
                next();
              });
        },
      },
    ],
  },
];
